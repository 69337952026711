/* eslint-disable no-useless-escape */
import React from 'react'
import { graphql } from 'gatsby'
// import BackgroundImage from 'gatsby-background-image'
import Layout from '../components/Layout/index'
import Pagina from '../components/Pagina'
// import { Collapse, CardBody, Card } from 'reactstrap'
import './styles/trabalhe-conosco.scss'
// import Axios from 'axios'
// import InputMask from 'react-input-mask'
import SectionBlogPost from '../components/SectionBlogPost/sectionBlogPost.js'
import Beneficios from "../images/Beneficios.png"
// import axios from 'axios'
// import iconemais from '../images/atendimento/botaomais.svg'
// import iconemenos from '../images/atendimento/botaomenos.svg'

// const sendEvent = (action, label = 'none') => {
//   // eslint-disable-next-line no-undef
//   if (typeof window !== 'undefined') {
//     if (!window.dataLayer) {
//       window.dataLayer = []
//     }
//     window.dataLayer.push({
//       event: 'curriculo',
//       eventAction: action,
//       eventLabel: label
//     })
//   }
// }

const TrabalheConosco = ({ data }) => {
  // var slugify = require('slugify')
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Home' },
      { path: '/trabalhe-conosco/', name: 'Trabalhe Conosco' }
    ]
  }

  // const [formState, setFormState] = useState([])
  // const [successMessage, setSuccessMessage] = useState('')
  // const [errorMessage, setErrorMessage] = useState('')
  // const [file, setFile] = useState('Nenhum arquivo anexado...')
  // const [dataKey, setDataKey] = useState('')
  // const [clickEnvio, setClickEnvio] = useState(false)

  // function validaEmail () {
  //   return !!formState.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
  // }

  // function uploadFile (event) {
  //   const file = event.target.files[0]
  //   tmpBucket(file)
  //   setFile(file)
  // }

  // function handleFormValidation () {
  //   setSuccessMessage(false)
  //   if (formState && Object.keys(formState).length < 7) {
  //     setErrorMessage('Preencha todos os campos')
  //   } else if (formState.nome.match(/[0-9]/gi)) {
  //     setErrorMessage('Nomes não podem conter números!')
  //   } else if (!validaEmail()) {
  //     setErrorMessage('Digite um Email Válido!')
  //   } else if (formState.telefone.length < 9) {
  //     setErrorMessage('Telefone Inválido!')
  //   } else if (formState.mensagem.length < 5) {
  //     setErrorMessage('Sua mensagem deve conter no mínimo 5 caracteres.')
  //   } else {
  //     return true
  //   }
  //   return false
  // }

  // const tmpBucket = async (file) => {
  //   const slugfiedString = slugify(file.name, { replacement: '_', lower: true })
  //   const getParameters = {
  //     params: {
  //       fileName: slugfiedString,
  //       contentType: file.type
  //     }
  //   }

  //   const { data } = await Axios.get('https://0iynl7qv67.execute-api.us-east-1.amazonaws.com/prd/upload/signed-url', getParameters)
  //   setDataKey(data.key)
  //   console.log(data)
  //   var formData = new FormData()
  //   formData.append('file', file)
  //   await fetch(data.signedUrl, {
  //     method: 'PUT',
  //     headers: {
  //       'Content-Type': file.type
  //     },
  //     body: file
  //   })
  // }

  // const uploadCurriculo = async (file) => {
  //   const slugfiedString = slugify(file.name, { replacement: '_', lower: true })
  //   const data = {
  //     fileName: slugfiedString,
  //     key: dataKey
  //   }
  //   await Axios({
  //     method: 'POST',
  //     data: data,
  //     url: 'https://0iynl7qv67.execute-api.us-east-1.amazonaws.com/prd/upload/curriculo'
  //   })
  // }

  // function resetForm () {
  //   formState.cargo = ''
  //   formState.nome = ''
  //   formState.email = ''
  //   formState.telefone = ''
  //   setFile('Nenhum arquivo anexado...')
  //   formState.lojaTrabalhe = 'Selecione uma loja'
  //   formState.mensagem = ''
  // }

  // async function enviarCurriculo (event) {
  //   event.preventDefault()
  //   if (handleFormValidation()) {
  //     setClickEnvio(true)
  //     await uploadCurriculo(file)
  //     const payload = {
  //       cargo: formState.cargo,
  //       nome: formState.nome,
  //       email: formState.email,
  //       telefone: formState.telefone,
  //       fileName: dataKey,
  //       loja: formState.lojaTrabalhe,
  //       mensagem: formState.mensagem,
  //       deficiencia: formState.possuiDeficiencia,
  //       pagina: 'trabalheconosco'
  //     }
  //     try {
  //       await Axios.post('https://0iynl7qv67.execute-api.us-east-1.amazonaws.com/prd/email', payload)
  //       sendEvent('form', formState.email)
  //       setErrorMessage(false)
  //       setSuccessMessage(<>Curriculo enviado com sucesso!</>)
  //       resetForm()
  //       setClickEnvio(false)
  //     } catch (err) {
  //       setSuccessMessage(false)
  //       setErrorMessage(<>Não foi possível concluir o envio. <br/> Por favor, verifique os dados e tente novamente! </>)
  //       setClickEnvio(false)
  //     }
  //   }
  // }

  // const setInput = (e) => {
  //   setFormState({ ...formState, [e.target.name]: e.target.value })
  // }

  // const [telMask, setTelMask] = useState([/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/])
  return (
    <Layout breadCrumb={breadC} >
      <Pagina pagina={data.pagina}/>
      <section className="container mt-4">
        <div className="row">
          <div className="col-12">
            <h1>Trabalhe Conosco</h1>
            <h2 className="text-black">Agradecemos pela iniciativa de fazer parte da nossa equipe :)</h2>
            {/* <p className="font-size-1">Envie o seu cv para <a href="mailto:trabalhe.conosco@grupozaragoza.com.br" className="ancoraTrabalheConosco">trabalhe.conosco@grupozaragoza.com.br</a>, no título do e-mail informe: Cargo desejado | Loja de interesse | Nome completo</p> */}
            <br />
            <p className="font-size-1 pt-3">Confira todos os nossos benefícos:</p>
            <img className='image-beneficios mt-2' src={Beneficios} alt="Benefícios" />
            <p className="font-size-1 pt-3">Para acessar o portal de carreiras do Spani clique aqui <strong>ou envie o seu currículo para:</strong></p>
            <a href="mailto:spanivagas@spani.com.br" className="ancoraTrabalheConosco">
              spanivagas@spani.com.br
            </a>
          </div>
        </div>
        <div className='row'>
          <div className="col-12 mt-2 mb-5">
            <a
              href='https://spani.gupy.io/'
              className="btn btn-clique-aqui btn-yellow text-uppercase font-openSans-extrabold d-relative px-5"
              target="_blank" without rel="noreferrer"
            >Clique aqui</a>
          </div>
        </div>
      </section>
      {/* <div className="bg-gray2 mt-5">
        <div className="container pt-4 pb-4">
          <div className="row">
            <div className="col-12">
              <h2 className="text-black">Cadastre o seu currículo</h2>
            </div>
          </div>
          <form className="w-100 card pt-3 pb-3 form-spani">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 mb-2">
                  <label htmlFor="nome" className="mb-0 mt-lg-0 mt-2">Nome Completo*</label>
                  <input type="text" id="nome" className="w-100" name="nome" onChange={(e) => setInput(e)} value={formState.nome} />
                </div>
                <div className="col-lg-3 mb-2">
                  <label htmlFor="cargo" className="mb-0 mt-0">Informe o cargo*</label>
                  <input type="text" id="cargo" className="w-100" name="cargo" onChange={(e) => setInput(e)} value={formState.cargo} />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="possuiDeficiencia" className="mb-0 mt-lg-0 mt-2">Possui algum tipo de deficiência*</label>
                  <select type="text" id="possuiDeficiencia" name="possuiDeficiencia" onChange={(e) => setInput(e)} className="w-100" value={formState.possuiDeficiencia}>
                    <option value='' selected disabled>
                      Selecione uma opção
                    </option>
                    <option value="Sim">
                      Sim
                    </option>
                    <option value="Não">
                      Não
                    </option>
                  </select>
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="email" className="mb-0 mt-2">E-mail*</label>
                  <input type="text" id="email" className="w-100" name="email" onChange={(e) => setInput(e)} value={formState.email} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="telefone" className="mb-0 mt-2">Telefone / Celular*</label>
                  <InputMask mask={telMask} maskPlaceholder={null} type="text" id="telefone" className="w-100" name="telefone" value={formState.telefone} onChange={(e) => {
                    setInput(e)
                    if (e.target.value.match(/\d{2}\s\d{4}-\d{5}/)) {
                      setTelMask([/\d/, /\d/, ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/])
                    } else {
                      setTelMask([/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/])
                    }
                  }} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="curriculo" className="mb-0 mt-2">Anexar CV*</label>
                  <span className="upload-spani d-flex justify-content-between p-0">
                    <p className='d-flex justify-content-center align-items-center m-0 pl-4'>
                      {file.name ? file.name : file}
                    </p>
                    <label className="btnUpload" htmlFor="curriculo" >Upload</label>
                  </span>
                  <input
                    className="form-control d-none"
                    type="file"
                    name="curriculo"
                    id="curriculo"
                    onChange={uploadFile}
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                    text/plain, application/pdf, image/*"
                  />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="lojaTrabalhe" className="mb-0 mt-2">Selecione uma loja*</label>
                  <select type="text" id="lojaTrabalhe" name="lojaTrabalhe" onChange={(e) => setInput(e)} value={formState.lojaTrabalhe} className="w-100">
                    <option value="" selected disabled>Selecione uma loja</option>
                    <option value='Matriz' key='matriz0'>
                      Matriz
                    </option>
                    <option value='Centro de distribuição' key='distrubuicao0'>
                      Centro de distribuição
                    </option>
                    {
                      data.lojas.edges.map(loja => {
                        return (
                          <option value={loja.node.nome} key={loja.node.id}>
                            {loja.node.nome}
                          </option>
                        )
                      })
                    }
                  </select>
                </div>
                <div className="col-lg-12 mb-2">
                  <label htmlFor="mensagem" className="mb-0 mt-2">Mensagem*</label>
                  <textarea type="text" id="mensagem" className="w-100" name="mensagem" onChange={(e) => setInput(e)} value={formState.mensagem} />
                </div>
                <div className="col-12 mt-2">
                  <button className="btn btn-yellow d-flex w-100 text-uppercase justify-content-center font-openSans-extrabold" disabled={clickEnvio === true} onClick={(e) => enviarCurriculo(e)}>cadastrar meu currículo</button>
                </div>
                {
                  !!errorMessage && (
                    <div className="col-lg-12">
                      <div className="d-flex bg-danger align-items-center justify-content-center py-2 px-4 my-1 rounded">
                        <p className="shadow rounded-circle text-white text-center align-self-center mb-0 mr-3"
                          style={{ width: '25px', height: '25px' }} >!</p>
                        <p className="text-center text-white mb-0 font-weight-bold w-75 fonterror">
                          {errorMessage}
                        </p>
                      </div>
                    </div>
                  )
                }
                {
                  !!successMessage && (
                    <div className="col-md-12">
                      <div className="d-flex align-items-center justify-content-center bg-success rounded p-2 my-3">
                        <p
                          className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-items-center"
                          style={{ width: '45px', height: '45px' }} >✔</p>
                        <p className="font-weight-bold text-white text-center font-size-1125 m-0">
                          {successMessage}
                        </p>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="col-12 pt-4">
              <p className="font-size-08">(*) Campos obrigatórios</p>
            </div>
          </form>
        </div>
      </div> */}
      <SectionBlogPost></SectionBlogPost>
    </Layout>
  )
}

export default TrabalheConosco

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/trabalhe-conosco/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
    desktop {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    mobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 414, height: 310, layout: CONSTRAINED)
        }
      }
    }
  }
  lojas: allContentfulLojas(sort: {order: ASC, fields: nome}) {
    edges {
      node {
        nome
        id
      }
    }
  }
}
`
